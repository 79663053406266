import React from "react";
import "twin.macro";
import { graphql } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

const EpisodeTemplate = (props) => {
  const {
    data: {
      feedEpisodes: {
        title,
        itunes: { episode, season },
        enclosure: { url },
        content: { encoded },
      },
    },
  } = props;
  return (
    <main>
      <section tw="relative text-gray-500 w-full">
        <StaticImage
          alt={title}
          src="../images/hm_banner.jpeg"
          loading="eager"
          layout="fullWidth"
          tw="h-full max-w-screen-lg mx-auto"
        />
      </section>
      <section tw="max-w-screen-lg mx-auto my-8 px-2">
        <h1 tw="font-ringbearer text-2xl md:text-3xl lg:text-4xl xl:text-5xl leading-relaxed my-16 text-center mx-2">
          {title}
        </h1>
        <h4 tw="w-full text-xl text-gray-100 leading-tight">
          {`Volume ${season}, Chapter ${episode}`}
        </h4>
        <iframe
          src={`${url.replace(
            ".mp3",
            ""
          )}?client_source=small_player&iframe=true`}
          loading="lazy"
          width="100%"
          height="200"
          frameBorder="0"
          scrolling="no"
          title="The Tales of Hobgob & Mulch, The Realm - The Tales of Hobgob & Mulch, Volume I, Chapter I"
        ></iframe>
        <div
          tw="prose mx-auto mt-8"
          dangerouslySetInnerHTML={{ __html: encoded }}
        />
      </section>
    </main>
  );
};

export const query = graphql`
  query EpisodeQuery($id: String!) {
    feedEpisodes(id: { eq: $id }) {
      title
      itunes {
        episode
        season
      }
      enclosure {
        url
      }
      content {
        encoded
      }
    }
  }
`;

export default EpisodeTemplate;
